<template #page-content>
  <r-page>
    <template #page-header>
      <h2 class="page-title">Change Password</h2>
    </template>
    <template #page-content>
      <div class="columns">
        <div class="column is-full">
          <validation-observer ref="observer" v-slot="{ handleSubmit }">
            <form @submit.prevent="handleSubmit(onSubmit)">
              <validation-provider
                name="Current Password"
                rules="required|upCase|number|minPass:8"
                v-slot="{ errors }"
                immediate
                :bails="false"
              >
                <b-field label="Current Password">
                  <b-tooltip
                    style="width: 100%; max-width: 100%"
                    :triggers="['click']"
                    :auto-close="['outside', 'escape']"
                    type="is-dark"
                    expanded
                    multilined
                  >
                    <template v-slot:content>
                      <div class="password-tooltip-content">
                        Please make sure that your current password includes :
                        <p v-for="(message, m) in errorRules(errors)" :key="m">
                          <span v-if="message.isValid">
                            <img :src="urlValid" />
                          </span>
                          <span v-else><img :src="urlInvalid" /></span>
                          {{ message.text }}
                        </p>
                      </div>
                    </template>
                    <b-input
                      class="password-eye"
                      type="password"
                      placeholder="current password"
                      password-reveal
                      validate-vv-on="focus"
                      @input="errorRules"
                      v-model="form.currentPassword"
                    ></b-input>
                  </b-tooltip>
                </b-field>
              </validation-provider>
              <validation-provider
                name="New Password"
                rules="required|upCase|number|minPass:8"
                v-slot="{ errors }"
                vid="newPassword"
                immediate
                :bails="false"
              >
                <b-field label="New Password">
                  <b-tooltip
                    style="width: 100%; max-width: 100%"
                    :triggers="['click']"
                    :auto-close="['outside', 'escape']"
                    type="is-dark"
                    expanded
                    multilined
                  >
                    <template v-slot:content>
                      <div class="password-tooltip-content">
                        Please make sure that your new password includes :
                        <p v-for="(message, m) in errorRules(errors)" :key="m">
                          <span v-if="message.isValid">
                            <img :src="urlValid" />
                          </span>
                          <span v-else><img :src="urlInvalid" /></span>
                          {{ message.text }}
                        </p>
                      </div>
                    </template>
                    <b-input
                      class="password-eye"
                      type="password"
                      placeholder="new password"
                      password-reveal
                      validate-vv-on="focus"
                      @input="errorRules"
                      v-model="form.newPassword"
                    ></b-input>
                  </b-tooltip>
                </b-field>
              </validation-provider>
              <validation-provider
                name="Confirm New Password"
                rules="required|confirmed:newPassword"
                v-slot="{ errors }"
                mode="lazy"
              >
                <b-field
                  label="Confirm New Password"
                  :type="errors[0] && 'is-danger'"
                  :message="errors[0]"
                  class="input-field"
                >
                  <b-input
                    class="password-eye"
                    type="password"
                    placeholder="confirm new password"
                    password-reveal
                    v-model="form.confirmPassword"
                  />
                </b-field>
              </validation-provider>
              <b-button
                native-type="submit"
                label="Change Password"
                :loading="isLoading"
              />
            </form>
          </validation-observer>
        </div>
      </div>
    </template>
  </r-page>
</template>

<script>
import ApiService from '@/services/common/api.service'
import { showToast } from '@/services/util'

export default {
  name: 'ChangePassword',
  data() {
    return {
      form: {
        currentPassword: '',
        newPassword: '',
        confirmPassword: '',
      },
      isLoading: false,
      tooltipMessages: [
        'Lowercase and uppercase letters',
        'at least one number',
        '8 or more characters',
      ],
      urlValid: require('@/assets/images/create-password/success.svg'),
      urlInvalid: require('@/assets/images/create-password/error.svg'),
    }
  },
  methods: {
    errorRules(e) {
      return this.tooltipMessages.map((message) => {
        let isValid = !e.includes(message)
        return {
          isValid,
          text: message,
        }
      })
    },
    async onSubmit() {
      this.isLoading = true
      try {
        const response = await ApiService.post(
          `/api/space-roketin/user/change-password-mobile`,
          this.form,
          false
        )
        this.resetForm()
        showToast(response.data.message, 'is-success', 'is-top')
      } catch (error) {
        showToast(error.response.data.message, 'is-danger', 'is-top')
      }
      this.isLoading = false
    },
    resetForm() {
      this.form = {
        currentPassword: '',
        newPassword: '',
        confirmPassword: '',
      }
      this.$refs.observer.reset()
    },
  },
}
</script>

<style scoped lang="scss">
form {
  display: flex;
  flex-direction: column;
  gap: 18px;
  width: 30%;
}
</style>
