var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('r-page',{scopedSlots:_vm._u([{key:"page-header",fn:function(){return [_c('h2',{staticClass:"page-title"},[_vm._v("Change Password")])]},proxy:true},{key:"page-content",fn:function(){return [_c('div',{staticClass:"columns"},[_c('div',{staticClass:"column is-full"},[_c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.onSubmit)}}},[_c('validation-provider',{attrs:{"name":"Current Password","rules":"required|upCase|number|minPass:8","immediate":"","bails":false},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Current Password"}},[_c('b-tooltip',{staticStyle:{"width":"100%","max-width":"100%"},attrs:{"triggers":['click'],"auto-close":['outside', 'escape'],"type":"is-dark","expanded":"","multilined":""},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('div',{staticClass:"password-tooltip-content"},[_vm._v(" Please make sure that your current password includes : "),_vm._l((_vm.errorRules(errors)),function(message,m){return _c('p',{key:m},[(message.isValid)?_c('span',[_c('img',{attrs:{"src":_vm.urlValid}})]):_c('span',[_c('img',{attrs:{"src":_vm.urlInvalid}})]),_vm._v(" "+_vm._s(message.text)+" ")])})],2)]},proxy:true}],null,true)},[_c('b-input',{staticClass:"password-eye",attrs:{"type":"password","placeholder":"current password","password-reveal":"","validate-vv-on":"focus"},on:{"input":_vm.errorRules},model:{value:(_vm.form.currentPassword),callback:function ($$v) {_vm.$set(_vm.form, "currentPassword", $$v)},expression:"form.currentPassword"}})],1)],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"New Password","rules":"required|upCase|number|minPass:8","vid":"newPassword","immediate":"","bails":false},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"New Password"}},[_c('b-tooltip',{staticStyle:{"width":"100%","max-width":"100%"},attrs:{"triggers":['click'],"auto-close":['outside', 'escape'],"type":"is-dark","expanded":"","multilined":""},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('div',{staticClass:"password-tooltip-content"},[_vm._v(" Please make sure that your new password includes : "),_vm._l((_vm.errorRules(errors)),function(message,m){return _c('p',{key:m},[(message.isValid)?_c('span',[_c('img',{attrs:{"src":_vm.urlValid}})]):_c('span',[_c('img',{attrs:{"src":_vm.urlInvalid}})]),_vm._v(" "+_vm._s(message.text)+" ")])})],2)]},proxy:true}],null,true)},[_c('b-input',{staticClass:"password-eye",attrs:{"type":"password","placeholder":"new password","password-reveal":"","validate-vv-on":"focus"},on:{"input":_vm.errorRules},model:{value:(_vm.form.newPassword),callback:function ($$v) {_vm.$set(_vm.form, "newPassword", $$v)},expression:"form.newPassword"}})],1)],1)]}}],null,true)}),_c('validation-provider',{attrs:{"name":"Confirm New Password","rules":"required|confirmed:newPassword","mode":"lazy"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{staticClass:"input-field",attrs:{"label":"Confirm New Password","type":errors[0] && 'is-danger',"message":errors[0]}},[_c('b-input',{staticClass:"password-eye",attrs:{"type":"password","placeholder":"confirm new password","password-reveal":""},model:{value:(_vm.form.confirmPassword),callback:function ($$v) {_vm.$set(_vm.form, "confirmPassword", $$v)},expression:"form.confirmPassword"}})],1)]}}],null,true)}),_c('b-button',{attrs:{"native-type":"submit","label":"Change Password","loading":_vm.isLoading}})],1)]}}])})],1)])]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }